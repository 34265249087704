import React, { useEffect, useRef, useState } from 'react'
import './TripDayPlanning.scss'
import Title from '../Title/Title'
import ReactHtmlParser from 'react-html-parser'

export default ({ cards, variant }) => {
	// if (typeof cards === 'undefined') return null

	const [activeItem, setActiveItem] = useState()
	const [pageHeight, setPageHeight] = useState(
		typeof window !== 'undefined' && window.innerHeight,
	)

	useEffect(() => {
		setPageHeight(window.innerHeight)
		window.addEventListener('resize', (e) => {
			setTimeout(() => {
				setPageHeight(
					typeof window !== 'undefined' && window.innerHeight,
				)
			}, 300)
		})
	}, [])

	const refs = cards.reduce((refsObj, card) => {
		refsObj[card.day.name] = useRef()
		return refsObj
	}, {})

	const handleClick = (name) => {
		refs[name].current.scrollIntoView({
			behavior: 'smooth',
		})
	}

	return (
		<div className={'trip-day-planning'} id={'reis'}>
			<div className="trip-day-planning__navigation-scroll">
				<ol className="trip-day-planning__navigation">
					{cards.length
						? cards.map((card, i) => (
								<li key={`${card.day.name}-${i}`}>
									<button
										id={card.day.name}
										onClick={() =>
											handleClick(card.day.name)
										}
										className={`trip-day-planning__navigation-item${
											activeItem === card.day.name
												? ' active'
												: ''
										}`}
									>
										<span>{variant}</span>
										<span>{card.day.number}</span>
									</button>
								</li>
						  ))
						: null}
				</ol>
			</div>
			{cards.length
				? cards.map((card, i) => {
						const observerMargin = Math.floor(pageHeight / 2)
						const activeClass =
							activeItem === card.day.name ? ' active' : ''

						useEffect(() => {
							const observerConfig = {
								rootMargin: `-${
									pageHeight % 2 === 0
										? observerMargin - 1
										: observerMargin
								}px 0px -${observerMargin}px 0px`,
							}
							const handleIntersection = function(entries) {
								entries.forEach((entry) => {
									if (
										entry.target.dataset.name !==
											activeItem &&
										entry.isIntersecting
									) {
										setActiveItem(entry.target.dataset.name)
									}
								})
							}
							const observer = new IntersectionObserver(
								handleIntersection,
								observerConfig,
							)

							observer.observe(refs[card.day.name].current)
						}, [observerMargin, card.day])
						return (
							<section
								className={`trip-day-planning__wrapper${activeClass}`}
								key={`${card.day.title}-${i}`}
								ref={refs[card.day.name]}
								data-name={card.day.name}
							>
								<footer
									className={'trip-day-planning__day-card'}
								>
									<div
										className={
											'trip-day-planning__day-card--holder'
										}
									>
										<Title
											className={
												'trip-day-planning__day-card--title'
											}
											text={card.day.name}
											wave={'true'}
										/>
										<Title
											text={card.day.title}
											className={
												'secondary trip-day-planning__day-card--subtitle'
											}
										/>
									</div>
								</footer>
								<article className={'trip-day-planning__card'}>
									{Object.keys(card.content).map((key, i) => {
										// is image
										if (card.content[key].src) {
											return (
												<img
													className={
														'trip-day-planning__card--image'
													}
													key={`${key.alt}__${i}`}
													src={card.content[key].src}
													alt={card.content[key].alt}
												/>
											)
										} else {
											return (
												<div
													key={`
														${card.content[key].title}---${i}`}
													className={
														'trip-day-planning__card--content'
													}
												>
													<p
														className={
															'trip-day-planning__card--title'
														}
													>
														{
															card.content[key]
																.title
														}
													</p>
													<div
														className={
															'trip-day-planning__card--text'
														}
													>
														{ReactHtmlParser(
															card.content[key]
																.text,
														)}
													</div>
												</div>
											)
										}
									})}
								</article>
							</section>
						)
				  })
				: null}
		</div>
	)
}
